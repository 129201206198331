html {
    font-size: 18px;
}

.print-container {
    width: 1150px;
    position: relative;
    overflow: hidden;
    padding: 0 3px;
    margin: 0 auto;
    color:black;
}

.print-container .title {
    margin-bottom: 0;
    text-align: center;
}

.print-container .bcmheader {
    margin-bottom: 0;
    width: 100%;
}

.print-container .bcmheader li,
.print-container .footer li {
    display: inline-block;
    width: 33.33%;
    white-space: nowrap;
}

.print-container .footer li.note {
    width: 89%;
    height: 46px;
    white-space: normal;
    position: relative;
    overflow: hidden;
}

.print-container .footer li.pagination {
    width: 10%;
    text-align: right;
}

.print-container .footer li.w20 {
    width: 20%;
}
.print-container .footer li.w16 {
    width: 16.5%;
}

.print-container .bcmheader li.w20 {
    width: 20%;
}
.print-container .bcmheader li.w25 {
    width: 25%;
}
.print-container .bcmheader li.w30 {
    width: 30%;
}
.print-container .bcmheader li.w40 {
    width: 40%;
}
.print-container .bcmheader li.w45 {
    min-width: 45%;
    max-width:45%;
}
.print-container .bcmheader li.h34 {
    height: 34px;
}

.print-container .bcmheader li.h34_w30 {
    height: 34px;
    width: 30%;
}

.print-container .bcmheader li.h34_w40 {
    height: 34px;
    width: 40%;
}

.mingxi {
    width: 100%;
}

.mingxi,
td {
    border-collapse: collapse;
}


/*明细表格线*/

.mingxi {
    margin: 0;
}

.mingxi td,
.mingxi th {
    border: 1px solid #000;
}

.mingxi td,
.mingxi th {
    padding: 3px 1px;
    text-align: left;
}

.print-center td {
    text-align: center;
    /* white-space: nowrap; */
}

.print-center td:nth-child(1) {
    /* width: 90px; */
    max-width: 150px;
    text-align: left;
    padding-right: 20px;
}

.print-center td:nth-child(2) {
    max-width: 280px;
    text-align: left;

}

.print-center td:last-child {
    width: 150px;
}
.f-W600{
    font-weight: 600;
}