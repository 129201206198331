
.offerPrint-container{
    width: 1150px;
    margin: 0 auto;
    font-size: 14px;
}
.title{
    text-align: center;
    margin-bottom: 0;
}
.offerPrint-container>.offerPrint-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;



}
.offerPrint-container .offerPrint-header li{
    margin-bottom:10px ;
    width:25%;
    white-space: nowrap;
/* .offerPrint-container .footer li { */
    /* display: inline-block;
    white-space: nowrap; */
}
.w40{
    width: 40% !important;
}
.w30{
    width: 30% !important;
}
.w20{
    width: 20% !important;
}
.w15{
    width: 15% !important;
}
.w10{
    width: 10% !important;
}
.li-right{
    text-align: right;
}
/* 明细表格线 */
.mingxi{

}
.mingxi td,
.mingxi th {
    border: 1px solid #333;
    padding: 3px 1px;
    /* text-align: left; */
}
.border-right-none{
    border-right: 1px solid transparent !important;
}
.border-left-none{
    border-right: 1px solid transparent !important;
}
.print-left{
    text-align: left;
    padding: 3px 1px;
}
.print-center td {
    text-align: center;
    /* white-space: nowrap; */
}

.print-center td:nth-child(1) {
    width: 90px;
}

.print-center td:nth-child(2) {
    width: 150px;
}

.print-center td:last-child {
    width: 150px;
}
.print-total{
    width: 100%;
    height: 25px;
    line-height: 25px;
    /* display: flex; */
    /* justify-content: space-between; */
    
    border-bottom:1px solid black
}
.print-total span{
    width: 40%;
    display: inline-block;
}
/* 底部信息展示 */
.offerPrint-container .footer p{
    margin: 5px;
}
.offerPrint-container .footer .footer-people{
    width: 100%;
    margin-top: 10px;
    padding-left: 5px;
    margin-bottom: 0;
}
.offerPrint-container .footer .footer-people li{
    width: 16.6%;
    display: inline-block;
}
.note-footer{
    padding-bottom:5px;
    border-bottom: 1px dashed #333;
}