.scaina-contaier{
    position: relative;
    overflow: hidden;
    padding: 3px;
    width: 700px;
    font-size:12px;
    margin:0 auto;
}
.scaina-container>p,.scaina-container h2,.scaina-container h1{
    margin:0;
    padding:0;
}
.scaina-contaier .scaina-header{
    display: flex;
    justify-content: space-between;
}
.header-left {
    width: 60%;
    vertical-align: top;
}
.header-right {
    width: 38%;
    vertical-align: top;
}
.header-left .logo{
    width:160px;
    height: 51px;
}
.header-left .logo img{ 
    /* width:100%; */
    height:100%
}
.header-left h1{ 
    text-align: center;
    letter-spacing: 5px;
    padding:50px;
    font-size: 30px;
    font-weight: 500;
}
.header-right h2{
    display: inline-block;
    letter-spacing: 5px;
    font-weight: 500;
    font-size: 20px;
    padding: 12px 0;
    border-bottom: 1px solid #ccc;
}
table {
    width: 100%;
}
table,
td {
    border-collapse: collapse;
}

table td {
    padding: 3px;
    text-align: left;
}
/*明细表格线*/
.mingxi{
    margin:5px 0;
}
.mingxi td {
    border: 1px solid #333;
}
.print-center td {
    text-align: center;
}

.print-center td:nth-child(1) {
    width: 80px;
}

.print-center td:nth-child(2) {
    width: 140px;
}

.print-center td:last-child {
    width: 100px;
}

/*备注*/
.re td {
    padding: 0;
}

.re td p {
    margin-top: 10px;
    font-size: 15px;
    margin-bottom: 0;
}
/*委托*/
.weituo {
    padding: 10px 0px;
    font-size: 20px;
    font-weight: bold;
}

.weituo span {
    display: inline-block;
    width: 200px;
    border-bottom: 1px solid #aaa;
}