.yh-but {
    width: 26px;
    display: inline-block;
    position: relative;
    text-align: center;
    line-height: 1;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    // margin-right: 15px;
    cursor: pointer;

    &-disabled {
        cursor: no-drop;
    }

    span {
        display: inline-block;
    }

    &-icon {
        position: relative;
        width: 18px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        border-radius: 50%;

        &::before {
            content: " ";
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            opacity: 0;
            background: none;
            border: 1px solid #C56089;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            border-radius: 50%;
            -moz-transition: all 0.3s cubic-bezier(0.53, -0.67, 0.79, 0.74);
            -o-transition: all 0.3s cubic-bezier(0.53, -0.67, 0.79, 0.74);
            -webkit-transition: all 0.3s cubic-bezier(0.53, -0.67, 0.79, 0.74);
            transition: all 0.3s cubic-bezier(0.53, -0.67, 0.79, 0.74);
        }
    }

    &:active &-icon::before {
        opacity: 1;
        border: 1px solid #A8CFCB;
        -moz-transform: scale(1.15);
        -ms-transform: scale(1.15);
        -webkit-transform: scale(1.15);
        transform: scale(1.15);
        -moz-transition-timing-function: cubic-bezier(0.37, 0.74, 0.15, 1.65);
        -o-transition-timing-function: cubic-bezier(0.37, 0.74, 0.15, 1.65);
        -webkit-transition-timing-function: cubic-bezier(0.37, 0.74, 0.15, 1.65);
        transition-timing-function: cubic-bezier(0.37, 0.74, 0.15, 1.65);
    }

    &-txt {
        font-size: 10px;
        font-weight: 500;
        transform: scale(0.88);
    }

}
@color-bright-1: #FFFFFF;@color-blue-1: #F9FAFF;@color-blue-2: #6C72F3;@color-blue-3: #90a4fe;@color-blue-4: #BDC7FC;@color-blue-5: #2E3AEB;@color-blue-6: #D7DEFE;@color-dark-0: #D6D6D6;@color-dark-1: #3d4757;@color-dark-2: #6B6B6B;@color-dark-3: #555555;@color-dark-4: #434343;@color-dark-5: #29292c;@color-dark-6: #010002;@color-dark-7: #707070;@color-red: #FF0B00;@color-green: #028E19;@font-size-base: 16px;@font-size-lg: 18px;@font-size-sm: 14px;