.layout-sider {

	&-logo {
		box-sizing: border-box;
		display: flex;
		color: #238FF2;
		display: flex;
		font-weight: 500;
		align-items: flex-end;
		justify-content: center;

		&-box {
			padding-bottom: 6px;
			display: flex;
			align-items: flex-end;
			line-height: 1;
			width: 100%;
		}

		&-img {
			height: 34px;
			margin-right: 8px;
		}


	}

	&-menu {
		// margin-top: 55px;
		overflow-x: hidden;
		overflow-y: auto;
		height: calc(100vh - 120px - 64px);

		&-button {
			margin: 16px auto !important;
			width: 150px !important;
		}

		&-button--small {
			margin: 16px auto !important;
			width: 60px !important;
			min-width: 60px;
		}
	}
}

.layout-section {

	&-header {
		box-sizing: border-box;
		height: 64px;
		padding: 10px 30px 10px 20px;
		display: flex;
		line-height: 1;
		justify-content: space-between;
		align-items: center;
		box-shadow: 0px 2px 1px 0px rgba(33, 34, 34, 0.08);
		z-index: 1;

		.header-left {
			cursor: pointer;
			padding: 10px;
			font-size: 18px;
		}

		.header-right {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;

			.header-right-item+.header-right-item {
				margin-left: 10px;
			}

			.config-icon {
				width: 32px;
				color: #7D7D7D;
				background: #ffffff !important;
				border: 1px solid #7D7D7D;
				cursor: pointer;
			}

			.user-info {
				color: #02a7f0;
				cursor: pointer;
				vertical-align: middle;

				span.account {
					vertical-align: middle;
				}
			}

			.user-icon {
				margin-right: 10px;
			}

			.logout-icon {
				color: #D82007;
				background-color: #ffffff;
				border: none;
				cursor: pointer;
				font-size: 32px;
			}
		}
	}

	&-nav {
		min-height: 58px;
		padding: 10px 8px;
		white-space: nowrap;
		overflow-x: auto;
		overflow-y: hidden;
	}

	&-content {
		margin: 0px 18px 16px;
	}
}

//dict

.dict-container {
	position: relative;
	padding: 0 16px 10px;
	height: calc(100vh - 322px);

	.dict-list-box {
		display: inline-block;
		width: 37%;
		height: 100%;
		min-height: 500px;
		position: relative;
		border: 2px solid #EBEBEC;
		margin-right: 1%;
		vertical-align: top;
		overflow-y: auto;
		padding: 23px 0 0 40px;
	}

	.dict-from-box {
		width: 62%;
		height: 100%;
		min-height: 500px;
		position: relative;
		overflow-y: auto;
		background: #fff;
		border: 2px solid #EBEBEC;
		vertical-align: top;
		flex-direction: column;

		.dict-list-header {
			padding: 16px 0 0 16px;
		}

		.dict-from-content {
			padding: 30px;
		}

		.dict-init-box {
			width: 100%;
			height: 100%;
			text-align: center;
			font-size: 20px;
			font-weight: bold;
		}
	}

}


//通用 左右布局
.use-warp {
	position: relative;
	padding: 0 16px 10px;
	height: calc(100vh - 284px);
	display: flex;

	&>div {
		height: 100%;
		min-height: 300px;
		position: relative;
		border: 2px solid #EBEBEC;
		background: #fff;
		vertical-align: top;
		overflow-y: hidden;
	}

	&>div:first-child {
		margin-right: 1%;
		padding: 23px;
		overflow: auto
	}

	&>div:last-child {
		flex-direction: column;
		flex: 1;

		.header-content {
			padding: 16px;
			white-space: nowrap;
		}

		.list-content {
			height: calc(100% - 64px);
			position: relative;
			overflow-y: auto;
		}
	}
}

//图片列表
.img-file-box {
	position: relative;
	width: 120px;
	height: 120px;
	border: 1px solid #aaa;
	display: inline-block;
	margin-right: 10px;
	vertical-align: middle;

	span.ant-badge {
		width: 100%;
	}

	div.ant-image {
		width: 100%;
		text-align: center;
		height: 120px;
		line-height: 120px;
		overflow: hidden;
	}
}

//重设置图片的col 栅格
.file-image-box {
	.ant-form-item-label {
		flex: 0 0 6.333333%;
		max-width: 6.333333%;
	}
}

//点选表格得每行样式*/
.table-select-row,
.table-select-row>.ant-table-cell-fix-left {
	color: #2e3aeb;
	background: #E9ECFB;
}

//tabs 按钮选中的样式
.tab-buts-active {
	background-color: #2E3AEB;
}
//尝试设置表格不断层
.ant-table-wrapper {
    :global {
      .ant-table-scroll table .ant-table-fixed-columns-in-body {
        visibility: visible;
      }
    }
  }
//详情页贴下边的展示框
.details-bot-fixed-warp {
	position: fixed;
	right: 0;
	bottom: 0;
	height: 60px;
	background: #FFFFFF;
	box-shadow: 0px -3px 0px 0px rgba(33, 34, 34, 0.1);
	line-height: 60px;
	transition: all .3s;
	z-index: 9;
}

//详情备注 col 栅格
.detail-note-col {
	.ant-form-item-label {
		display: block;
		flex: 0 0 15%;
		max-width: 15%;
	}
}

//详情备注 全部栅格
.detail-all-col {
	.ant-form-item-label {
		display: block;
		flex: 0 0 6.22%;
		max-width: 6.22%;
	}
}

.w65px {
	width: 65px;
}

.f1 {
	flex: 1;
}

//入库登记  记录表单框
.record-warp {
	display: flex;
	width: 100%;
	height: 36px;
	margin: 8px 0;
	border: 1px solid #aaa;

	.record-item {
		display: flex;
		width: 25%;

		label {
			width: 80px;
			text-align: center;
			line-height: 36px;
			border-right: 1px solid #aaa;
			border-left: 1px solid #aaa;
		}

		& .text {
			display: inline-block;
			width: 100%;
			padding-left: 8px;
			line-height: 36px;
			color: #aaa;
		}
	}
}

//流程图盒子
.image-box {
	width: 100%;
	display: flex;
	justify-content: center;

	img {
		// width: 100%;
	}
}

.padding-rt-10 {
	padding: 0 10px 10px;
}

//表格合计样式
.table-bottom-total {
	width: 100%;
	height: 35px;
	padding: 6px 16px;
	border-bottom: 1px solid #f0f0f0;
	font-weight: 600;
}
.layout-sider-shop {
    position: relative;
    overflow: hidden;
    padding: 0 15px;
    height: 55px;
    line-height: 55px;
    font-size: 22px;
    color: #2e3aeb;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
}