.settle-container {
    width: 700px;
    position: relative;
    overflow: hidden;
    padding: 3px;
    font-size: 12px;
    color: #333;
    margin: 0 auto;
    /* font-family:"STSong" */
}

p {
    margin: 0;
    padding: 0;
}

/*title*/
.settle-container .settle-header {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.settle-container .settle-header .h2 {
    display: inline-block;
    width: 60%;
    padding: 4px 0;
    border-bottom: 1px solid #000;
}

.settle-container .settle-header .h2 h2 {
    margin: 0;
    margin-bottom: 2px;
    padding: 0 0 22px;
    border-bottom: 1px solid #000;
}

/*logo*/
.settle-container .settle-header .logo {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 142px;
}

.settle-container .settle-header .skny {
    position: absolute;
    top: 0px;
    right: 1px;
    width: 165px;
    height: 70px;
}

.settle-container .settle-header .skny img {
    /* width: 100%; */
    height: 65%;
    overflow: hidden;
    vertical-align: top;
}

.settle-container .settle-header .logo img {
    width: 90px;
    vertical-align: top;
}

.settle-container .settle-header .logo>div {
    /* display: inline-block; */
    height: 46px;
    font-size: 16px;
    font-weight: 700;
}

.settle-container .settle-header .logo>div>p:last-child {
    font-size: 12px;
    font-weight: 800;
}

table {
    width: 100%;
}

table,
td {
    border-collapse: collapse;
}

.settle-container > table td {
    padding-bottom: 8px !important;
    text-align: left;
}

/*公里/每小时*/
table .span-right {
    text-align: right;
    display: inline-block;
    width: 65%;
}

.bottom-solid {
    border-bottom: 1px solid #333;
}

/*项目，配件明细*/
.ming-xi {
    margin-top: 20px;
    text-align: center;
}

.ming-xi tr:last-child,
.ming-xi tr:first-child {
    border-bottom: 1px solid #333;
}

.ming-xi .part {
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
}

.ming-xi .part td {
    padding-top: 20px;
}

.ming-xi td:nth-child(3) {
    width: 300px;
}

/*所有合计*/
.total-price {
    width: 100%;
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid #333;

}

.total-price>div {
    width: 55%;
    float: right;
}

.total-price>table:first-child {
    width: 55%;
    float: right;
}

.total-price>table:last-child {
    margin-top: 20px;
    width: 40%;
    float: right;
    text-align: right;
}

/* .total-price>table>tr:last-child{
    width:80%;
    float: right;
} */
.table-footer-height {
    /* height: 40px; */
}

.total-price>div>p>span:last-child {
    display: inline-block;
    margin-left: 30px;
    float: right;
}

.total-price>div>p.RMB>span {
    width: 70%;
    /* text-align: center; */
}

/*制单员*/
.print-flex {
    display: flex;
    justify-content: space-around;
}

/*签字*/
.sign-open {
    margin: 20px 0 20px;
    font-size: 20px;

}

.sign-open>div {
    display: inline-block;
    width: 45%;
}

.sign-open>div:first-child {
    margin-left: 44px;
}

/*温馨提醒*/
.warm-reminder {
    /* text-align: center; */
    font-size: 18px;
    font-weight: bold;
}

.footer>span {
    margin-left: 20px;
}

.footer>span:first-child {
    margin: 0;
}
.paybox{
    display: flex;
}
.paybox-item{
    width: 120px;
}