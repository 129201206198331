.header{
    min-width:1400px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    
    &-item{
        height:100px;
        text-align: center;
        border-radius: 10px;
        // white-space: nowrap;
        flex: 1;
        padding:20px 30px 30px 24px;
        line-height: 40px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        margin-right: 12px;
        cursor: pointer !important;
        
    }
    &-icon{
        font-size: 61px;
    }
    &-amount{
        display: flex;
        flex-direction: column;
        text-align: left;
        color:white
    }
    &-num{
        font-size: 28px;
        line-height:32px;
    }
    &-status{
        font-size: 23px;
        line-height: 23px;
    }
    &-item:nth-child(1) {
        background: linear-gradient(180deg, #F4BB4F, #FF6701);
        // border-radius: 10px;
    }
    &-item:nth-child(2) {
        background: linear-gradient(180deg, #FD8A7D, #FD1A01);
        // border-radius: 10px;
    }
    &-item:nth-child(3) {
        background: linear-gradient(180deg, #8EC359, #0E8D11);
        // border-radius: 10px;
    }
    &-item:nth-child(4) {
        background: linear-gradient(180deg, #86E3C9, #00936D);
        // border-radius: 10px;
    }
    &-item:nth-child(5) {
        background: linear-gradient(180deg, #75B1FF, #016DFA);
        // border-radius: 10px;
    }
    &-item:nth-child(6) {
        background: linear-gradient(180deg, #FF8A8A, #FE63D8);
        margin-right: 0 !important;

        // border-radius: 10px;
    }
}
.navigation{
    display: flex;
    justify-content: space-around;

    &-item{
        width: 120px;
        height: 120px;
        text-align: center;
        display: block;
        cursor: pointer;
        margin:0 auto;    
    }
    
    &-icon{
        width:80px;
        height:80px;
        border-radius: 50%;
        line-height: 60px;
        font-size: 40px;
        margin-bottom: 5px;   
    }
    &-menu{
        display: block;
        font-size: 18px;
        font-weight: 600;  

    }
    &-item:nth-child(1) &-icon{
    //    background: linear-gradient(180deg, #86E3C9, #00936D);
       background:#6C72F3;
       opacity: 0.5;
        // border-radius: 10px;
    }
    &-item:nth-child(2) &-icon{
    //    background: linear-gradient(180deg, #6C72F3, #00936D);
        background:#6C72F3;
        opacity: 0.5;
        // border-radius: 10px;
    }
    &-item:nth-child(3) &-icon{
    //    background: linear-gradient(180deg, #6C72F3, #00936D);
       background:#6C72F3;
       opacity: 0.5;
        // border-radius: 10px;
    }
    &-item:nth-child(4) &-icon{
        // background: linear-gradient(180deg, #FF8A8A, #FE63D8);
        // background:#FF8A8A;
       background:#6C72F3;
       opacity: 0.5;
        // border-radius: 10px;
    }
    &-item:nth-child(5) &-icon{
        // background: linear-gradient(180deg, #FF8A8A, #FE63D8);
        // background:#FF8A8A;
       background:#6C72F3;
       opacity: 0.5;
        // border-radius: 10px;
    }
    &-item:nth-child(6) &-icon{
        // background: linear-gradient(180deg, #FF8A8A, #FE63D8);
        // background:#FF8A8A;
       background:#6C72F3;
       opacity: 0.5;
        // border-radius: 10px;
    }
    &-item:nth-child(7) &-icon{
        // background: linear-gradient(180deg, #75B1FF, #016DFA);
        // background:#016DFA
        background:#6C72F3;
        opacity: 0.5;
        // border-radius: 10px;
    }
    &-item:nth-child(8) &-icon{
        // background: linear-gradient(180deg, #75B1FF, #016DFA);
        // background:#016DFA
        background:#6C72F3;
        opacity: 0.5;
        // border-radius: 10px;
    }
    &-item:nth-child(9) &-icon{
        // background: linear-gradient(180deg, #75B1FF, #016DFA);
        // background:#016DFA
        background:#6C72F3;
        opacity: 0.5;
        // border-radius: 10px;
    }
}
.ant-checkbox-group-item{
    width: 120px;
}
// .header li{
//     height: 113px;
//     margin: 0 20px;
//     // color: #fff;
//     text-align: center;
//     border-radius: 10px;
//     padding: 10px;
//     box-sizing: border-box;
// }
@color-bright-1: #FFFFFF;@color-blue-1: #F9FAFF;@color-blue-2: #6C72F3;@color-blue-3: #90a4fe;@color-blue-4: #BDC7FC;@color-blue-5: #2E3AEB;@color-blue-6: #D7DEFE;@color-dark-0: #D6D6D6;@color-dark-1: #3d4757;@color-dark-2: #6B6B6B;@color-dark-3: #555555;@color-dark-4: #434343;@color-dark-5: #29292c;@color-dark-6: #010002;@color-dark-7: #707070;@color-red: #FF0B00;@color-green: #028E19;@font-size-base: 16px;@font-size-lg: 18px;@font-size-sm: 14px;