.list-container {
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0 5px 12px 3px rgba(33, 34, 34, 0.1);
  overflow: hidden;
  padding: 10px 0;
  //height: calc(100vh - 65px);


  &.inline-block {
    margin-right: 15px;
    margin-bottom: 10px;
  }
}

.list-header {
  padding: 0 27px 0 15px;
  white-space: nowrap;
  color: @color-dark-4;

  &-title {
    margin-bottom: 10px;
    color: @color-dark-4;
    font-weight: bold;
  }

  &-content {

    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    &-item {
      font-size: 14px;
      margin-left: 30px;
      font-weight: bold;

     .scaleDraw{
      display: inline-block;
      animation: scaleDraw ease-in-out infinite 1.8s;
      -webkit-animation-name: scaleDraw;/*关键帧名称*/
      -webkit-animation-timing-function: ease-in-out;/*动画的速度曲线*/
      -webkit-animation-iteration-count: infinite;/*动画播放的次数*/
      -webkit-animation-duration:1.8s;/*动画所花费的时间*/
     }
    }

    &-statu-item {
      font-size: 14px;
      margin-right: 50px;
      font-weight: bold;
      display: inline-block;
    }

    &-money {
      font-size: @font-size-lg;
     
    }

    &-statu {
      font-size: @font-size-lg;
    }
  }


}

.list-buttons {
  padding: 0 15px 12px;
}

@keyframes scaleDraw {
  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
  0% {
      transform: scale(1);/*开始为原始大小*/
  }
  50% {
      transform: scale(3);/*放大1.1倍*/
  }
}

@color-bright-1: #FFFFFF;@color-blue-1: #F9FAFF;@color-blue-2: #6C72F3;@color-blue-3: #90a4fe;@color-blue-4: #BDC7FC;@color-blue-5: #2E3AEB;@color-blue-6: #D7DEFE;@color-dark-0: #D6D6D6;@color-dark-1: #3d4757;@color-dark-2: #6B6B6B;@color-dark-3: #555555;@color-dark-4: #434343;@color-dark-5: #29292c;@color-dark-6: #010002;@color-dark-7: #707070;@color-red: #FF0B00;@color-green: #028E19;@font-size-base: 16px;@font-size-lg: 18px;@font-size-sm: 14px;