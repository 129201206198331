.print-container {
    width: 1150px;
    position: relative;
    overflow: hidden;
    padding: 3px;
    margin: 0 auto;
    /* font-size: 16px; */
}
.print-container h2{
    text-align: center;
}
.print-container .header-odd{
    font-weight: 500;
    display: flex;
    justify-content: space-between;
}
table {
    width: 100%;
    /* margin: 5px 0; */
}

table,
td {
    border-collapse: collapse;
}


/* 表格明细线 */
.print-container td{
    font-weight: 500;
    padding: 3px 5px;
    /* text-align: right; */
    border: 1px solid #333;
}
.print-container td:nth-child(1){
    width: 120px;
}
.print-container td:last-child{
    width: 120px;
}
.item-table td:nth-child(2){
    width: 250px;

}
.material-table td:nth-child(2){
    width: 200px;    
}